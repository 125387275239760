.dropdown {
  &[data-variant='dark'] {
    background-color: var(--mantine-color-contrast-2);
    border: none;
    box-shadow: 0px 0px 12px 0px rgba(28, 27, 34, 0.12);
    color: var(--mantine-color-gray-3);
    overflow: hidden;
    z-index: 9999;
  }
}

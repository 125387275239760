.highlight {
  .mantine-Mark-root::after {
    display: inline-flex;
    content: 'animal';
    color: var(--mantine-color-green-6);
    font-size: 10;
    justify-content: center;
    margin-top: -5;
    font-weight: 600;
  }
}

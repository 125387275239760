.input[data-variant='default'] {
  border: 1px solid var(--mantine-color-gray-2);

  &:focus {
    border-color: var(--mantine-color-brand-6);
  }

  &[data-error] {
    color: unset;
    border-color: var(--mantine-color-red-6);
  }
}

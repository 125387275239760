.root {
  &[data-variant='gray-outline'] {
    color: var(--mantine-color-gray-8);
    background: white;
    border: 1px solid var(--mantine-color-gray-2);
    font-weight: 500;
  }

  &:hover {
    &[data-variant='gray-outline'] {
      background: var(--mantine-color-gray-1);
    }
  }
}

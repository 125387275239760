table.prompt-table tr:hover td:not(:last-child) {
  background: #f9f9fb;
}

table.prompt-table tr:hover td:last-child {
  visibility: visible !important;
}

table.prompt-table tr td {
  background-color: #ffffff;
  border: 1px solid #e9e8ed;
  padding: 8px;
  text-align: left;
}

table.prompt-table tr th {
  background-color: #f9f9fb;
  border: 1px solid #e9e8ed;
  padding: 8px;
  text-align: left;
  text-transform: unset;
}

@keyframes crown-animation {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(35deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-15deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.prompt-crown {
  position: relative;
  animation: crown-animation 0.75s;
  animation-delay: 0.3s;
}

.prompt-template-editor {
  flex-grow: 1;
  padding: 12px;
  padding-top: 6px;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 16px !important;
  white-space: pre-wrap;
}

.prompt-template-editor-input {
  caret-color: black;
}

.prompt-template-editor-input:empty:before {
  content: attr(placeholder);
  color: #b1afc0;
  font-size: 16px;
}

.prompt-template-editor-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

[data-testid='list-card-item']:last-of-type {
  border-bottom: none !important;
}

/* Minimal Variant Style Select */
.minimal-select .mantine-Select-root {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.minimal-select .mantine-Select-input {
  text-transform: capitalize;
  font-weight: 700;
}

.minimal-select .mantine-Select-input:focus {
  border: 0 !important;
  transition: none;
}

.minimal-select.no-border .mantine-Select-input {
  transition: none;
}

.minimal-select .mantine-Select-item {
  text-transform: capitalize;
}

.minimal-select .mantine-Select-label {
  margin-top: 2px;
  margin-right: 4px;
}

.minimal-select .mantine-MultiSelect-item[data-selected] {
  color: unset;
  background: unset;
}

.minimal-select .mantine-MultiSelect-item[data-selected]:hover {
  background: #f4f4f6;
}

.minimal-select .mantine-MultiSelect-wrapper {
  width: 250px;
}

.minimal-select.no-border .mantine-MultiSelect-input {
  border: 0;
}

.minimal-select.no-border .mantine-NumberInput-input {
  border: 0;
}

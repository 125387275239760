/* Table */
[data-testid='virtuoso-scroller'] {
  border-collapse: collapse;
  width: 100%;
}

[data-testid='virtuoso-scroller'] tr.table-row {
  border-bottom: 1px solid #e9e8ed;
  height: 50px;
}

[data-testid='virtuoso-scroller'] tr.table-row:hover {
  background-color: #e9e8ed;
}

[data-testid='virtuoso-scroller'] td.sticky-cell {
  background-color: #ffffff;
}

[data-testid='virtuoso-scroller'] tr.table-row:hover td.sticky-cell {
  background-color: #e9e8ed;
}

[data-testid='virtuoso-scroller'] td.sticky-cell:hover {
  background-color: #e9e8ed;
}

[data-testid='virtuoso-scroller'] tr.table-row.table-row-error {
  background-color: #ffd2d2;
}

[data-testid='virtuoso-scroller'] tr.table-row.table-row-error:hover {
  background-color: #db9090;
}

[data-testid='virtuoso-scroller'] tr.table-row:hover .prompt-crown {
  visibility: visible;
  transform: unset;
}

[data-testid='virtuoso-scroller'] tr.table-row:hover .context-menu {
  visibility: visible;
  transform: unset;
}

[data-testid='virtuoso-scroller'] tbody {
  position: relative;
  z-index: 1;
}

.table-row .context-menu {
  visibility: hidden;
  transform: scale(0);
}

.table-row.prompt-winner {
  background-color: #fffdf5;
}

.table-row:not(.prompt-winner) .prompt-crown {
  visibility: hidden;
  transform: scale(0);
}

.table-row.prompt-winner td .prompt-crown.winner {
  visibility: visible;
  transform: unset;
}

#user-tokens-table {
  border-radius: 8;
  td,
  th {
    border-bottom: 1px solid #e9e8ed;
  }
  th {
    border-radius: 8;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
}

.noBorder {
  mantine-Paper-root {
    border: '2px solid transparent';
  }
}

.withFocus {
  .mantine-Paper-root:focus {
    border-color: var(--mantine-color-brand-3);
  }
}

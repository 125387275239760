/* Default styles */
html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

thead tr th {
  text-transform: capitalize;
}

/* Mantine Overrides */
.mantine-Grid-root {
  margin: 0;
}

a.mantine-Button-root:disabled {
  border-color: transparent;
  background-color: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
}

.mantine-Notification-closeButton {
  align-self: flex-start;
}

.mantine-Menu-body {
  min-width: 275px;
}

.mantine-Menu-item {
  padding: 8px;
}

.mantine-Modal-header {
  display: flex;
  margin-bottom: 0;
  color: #706c89;
  font-weight: 600;
  font-size: 14px;
}

.mantine-Modal-close,
.mantine-Drawer-closeButton {
  align-self: flex-start;
}

.mantine-Select-input:focus {
  border-color: #b1afc0;
}

.mantine-Select-dropdown {
  min-width: 150px;
}

.mantine-Select-dropdown[data-position='bottom'] {
  margin-top: -8px;
}

.mantine-Select-item {
  display: flex;
  font-size: 14px;
  padding: 8px 8px 8px 32px;
}

.mantine-Select-item[data-selected],
.mantine-Select-item[data-selected]:hover {
  background: none;
  color: black;
  padding-left: 0;
}

.mantine-Select-item[data-selected]:hover {
  background-color: #f4f4f6;
}

.mantine-Select-item[data-selected]::before {
  content: '';
  display: inline-block;
  height: 0.875rem;
  width: 0.375rem;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  margin: 0 13px;
}

.mantine-Select-selected {
  color: white;
  background-color: #644dfa;
}

.mantine-Slider-root {
  width: 250px;
}

.mantine-Slider-markLabel {
  margin-top: 1px;
  font-size: 12px;
}

/* This is silly and I wish we didn't have to do it */
/* But there are overflow-x issues */
.mantine-ScrollArea-viewport > div {
  display: block !important;
}

.mantine-box-shadow-sm {
  box-shadow:
    0 1px 3px rgb(0 0 0 / 5%),
    rgb(0 0 0 / 5%) 0px 10px 15px -5px,
    rgb(0 0 0 / 4%) 0px 7px 7px -5px;
}

.scrollbox {
  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;

  background-repeat: no-repeat;
  background-color: #f9f9fb;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;

  background-attachment: local, local, scroll, scroll;
}

.right-fade {
  -webkit-mask: linear-gradient(90deg, #000 10% 95%, #0000);
  mask: linear-gradient(90deg, #000 10% 95%, #0000);
}

.mantine-Checkbox-input {
  border-radius: 4px;
}

input:checked + .mantine-Switch-track {
  background-color: #644df9;
  border-color: #644df9;
}

.replace {
  margin-left: 2px;
  font-weight: 600;
}

.ProseMirror {
  height: 75vh;
  overflow: hidden auto;
  padding: 16px;
}

/* Utility Classes - please keep alphabetized */

.align-center {
  display: flex;
  align-items: center;
}

.align-items-baseline {
  display: flex;
  align-items: baseline;
}

.align-self-center {
  align-self: center;
}

.box-shadow-right {
  box-shadow: rgb(0 0 0, 0.2) 10px 0px 15px -5px;
  transition: box-shadow 0.5s ease-in-out;
  /* border: 1px solid black; */
}

.hide-box-shadow-right {
  box-shadow: none;
  /* border: none; */
}

.box-shadow-sm {
  box-shadow:
    rgb(0, 0, 0, 0.2) 0px 1px 3px,
    rgb(0, 0, 0, 0.2) 0px 7px 7px -5px;
  transition: box-shadow 0.5s ease-in-out;
}

.button-unstyled {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.display-none {
  display: none;
}

.flex {
  display: flex;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-column {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.horizontal-flex-scroll {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-width: 100%;
}

.horizontal-flex-scroll div {
  flex-shrink: 0;
  margin: 0px 10px 0px 0px;
}

.inline-flex {
  display: inline-flex;
}

.italic {
  font-style: italic;
}

.justify-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.justify-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.justify-space-between-no-center {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.validation-header__icon {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  width: 64px;
  height: 64px;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.no-transform {
  text-transform: none !important;
}

.no-wrap {
  flex-wrap: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-only {
  overflow-x: hidden;
  overflow-y: auto;
}

.pipe::before {
  content: '';
  margin-left: -15px;
  border-radius: 8px;
  background: #bab0fd;
  width: 4px;
  height: 18px;
  position: absolute;
}

.pipe-yellow::before {
  content: '';
  margin-left: -15px;
  border-radius: 8px;
  background: #fce173;
  width: 4px;
  height: 18px;
  position: absolute;
  top: 0px;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.pre-wrap {
  white-space: pre-wrap;
}

.strike-through {
  text-decoration: line-through;
}

.tab-content {
  height: calc(100vh - 305px);
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.self-start {
  align-self: flex-start;
}

.unstyled-anchor {
  text-decoration: none;
  color: inherit;
}

.underline-hover:hover {
  text-decoration: underline;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-max-200 {
  max-width: 200px;
}

.white-space-nowrap {
  white-space: nowrap;
}

/* For Icons */
.h-6 {
  height: 16px;
}

.w-6 {
  width: 16px;
}

input[type='checkbox'],
input[type='radio'],
.mantine-Radio-label {
  cursor: pointer;
}

/* Click tooltip scrolling section */
.scrollable-text {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

.box-outline {
  background-color: #ffffff;
  border: 1px solid #e9e8ed;
  border-radius: 8px;
  padding: 8px;
  padding-right: 16px;
  position: relative;
}

/* Animations */
@keyframes in-out {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }

  70% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}

.animate-in-out {
  position: relative;
  animation: in-out 5s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translate(5px);
  transform-origin: center bottom;
}

.computing-metric {
  animation: fade-in-out 1.5s linear infinite alternate;
}
@keyframes fade-in-out {
  to {
    opacity: 0.25;
  }
}

.accordion-content .mantine-Accordion-content {
  padding-top: 0px;
}

.pre-white-space-break-spaces pre {
  white-space: break-spaces;
}

.hover-target .show-on-hover {
  visibility: hidden;
}

.hover-target:hover .show-on-hover {
  visibility: visible;
}

.mantine-Tooltip-tooltip {
  z-index: 9999 !important;
}
